<template>
  <div>
    <div class="d-flex flex-wrap justify-content-between align-items-end">
      <h2 class="mb-1">Environments</h2>
      <b-button variant="none" class="open-certificates-btn" @click="$refs['request-certificates-modal'].show()">
        <span>See also: <b>Request Certificates</b></span>
      </b-button>
    </div>
    <template v-if="Array.isArray(getEnvironments)"> <!-- if has loaded -->
      <vue-event-helper @mounted="initAnimation()"/>
      <div class="environments-outer-container" v-if="selectedEnv">
        <div align="center" class="list-envs-column position-relative">
          <div align="left" class="mb-50">
            <b-button variant="light" class="py-25" :disabled="loadingAdd" @click="addEnvironment()" v-if="$can('create')">
              <span class="text-black" v-if="!loadingAdd">
                <b-icon icon="plus-circle" class="mr-50"/>
                Add Environment
              </span>
              <b-spinner class="mx-4" v-else small/>
            </b-button>
          </div>

          <b-button
            v-for="environment in getEnvironments" 
            :key="'environment-'+environment.id"
            class="env-title-block px-1 d-flex justify-content-between align-items-center" 
            :class="[environment.id == selectedEnv.id ? 'selected' : '' , ('env-title-'+environment.id) ]"
            variant="none" 
            @click="selectEnv(environment)"
          >
            <div class="ml-1 my-50">
              <b-icon :icon="environment.id == selectedEnv.id ? 'key-fill': 'key'" rotate="-30" scale="1.1" class="mr-1"/>
              {{ environment.name }}
            </div>
            
            <!-- <b-icon icon="eye-fill" variant="light" :class="selectedEnv.id == environment.id ? '' : 'invisible'"/> -->
            
            <!-- <b-button variant="none" class="py-0 px-50">
              <b-icon icon="three-dots-vertical" variant="light"/>
            </b-button>  -->

            <b-dropdown class="p-0 m-0" size="sm" dropright no-caret variant="none" v-if="getEnvironments.length > 1 && $can('create')">
              <template #button-content>
              <b-icon icon="three-dots-vertical" variant="light"/>
              </template>

              <b-dropdown-item href="#" @click="openDeleteModal(environment)">
                <span class="text-danger font-weight-bolder">
                  <b-icon icon="trash" class="mr-50"/> Delete Env
                </span>
              </b-dropdown-item>
            </b-dropdown>
            <!-- <span/> -->

          </b-button>
        </div>
        <div class="details-env-column" v-if="selectedEnv" :key="'detail-env-column-'+selectedEnv.id">
          <div :class="isLocked ? 'locked-env-overlay' : 'env-detail-container'">
            <div class="d-flex justify-content-between align-items-start mb-50">
              
              <h4 class="pt-50 mb-1 text-black mx-1 font-weight-bold">
                <span v-if="editingName === false">{{ selectedEnv.name }}</span>
                <b-form-input class="bg-transparent text-black" v-else autofocus v-model="editingName" @blur="saveName()" @keydown.enter="activeElementBlur()"/> 

                <b-button v-if="editingName === false && $can('create')" variant="none" class="py-0 px-25 ml-50" @click="editingName = selectedEnv.name">
                  <b-icon icon="pencil-square" variant="black"/> 
                </b-button>
              </h4>

              <div class="mx-1 my-50 small text-black">
                Last updated {{ new Date(selectedEnv.updated_at).toLocaleDateString() }}
              </div>
            </div>
            
            <div align="center" class="centered" :class="loadingUnlock ? 'loading-unlock' : ''" v-if="isLocked">
              <b-icon v-if="!loadingUnlock" icon="lock-fill" scale="3" variant="black" class="my-1"/>
              <b-icon v-else icon="unlock-fill" scale="3" variant="black" shift-h="6" class="my-1"/>
              <h1 class="text-bla\ck m-0 title">
                <span v-if="!loadingUnlock">
                  LOCKED
                </span>
                <span v-else>UNLOCKING</span>
              </h1>
              <template v-if="!loadingUnlock">
                
                <div class="text-black">
                  This contains sensitive information 
                </div>
                <b-button class="py-50 px-2 mt-2" variant="light" @click="unlockEnv()" >
                  <span class="text-danger">
                    <span class="mx-1">UNLOCK</span>
                  </span>
                </b-button>
              </template>
              <template v-else>
                <b-spinner class="mt-1" variant="black"/>
              </template>
            </div>
            <div v-else @keydown.ctrl.enter="saveEnvironment()" @keydown.ctrl.s="((e)=>{e.preventDefault(); saveEnvironment()})">
              <json-editor 
                v-model="selectedEnvValue" 
                :modes="[ 'code', 'tree', 'preview' ]" 
                :readOnly="!$can('create')"
                height="60vh" 
                class="mb-50 mx-50"
                @input="canSave = true"
              />
              <div class="mx-50" align="right">
                <b-button variant="success" class="py-50" ref="save-env-btn" @click="saveEnvironment()" :disabled="loadingSave" :class="canSave ? '' : 'invisible'">
                  <span class="text-black font-weight-bolder" v-if="!loadingSave">
                    SAVE <b-icon icon="check"/>
                  </span>
                  <b-spinner small v-else class="mx-2"/>
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </template>

    <div v-else>
      <div class="environments-outer-container">
        <div class="list-envs-column">
          <b-skeleton height="50px" animation="wave" v-for="i in 8" :key="'skeleton-'+i"/>
        </div>
        <div class="details-env-column">
          <div class="p-1">
            <div class="d-flex justify-content-between">
              <b-skeleton width="200px" height="40px"/>
              <b-skeleton width="200px" height="20px"/>
            </div>
            <div align="center" class="mt-5 py-2">
              <b-skeleton width="100px" height="100px"/>
              <b-skeleton width="180px" height="20px"/>
              <b-skeleton width="150px" height="15px"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <b-modal
      ref="modal-delete-env"
      centered
      ok-variant="danger"
      auto-focus-button="ok"
      @ok="deleteEnvironment(deletingEnv)"
      @hide="((e)=>{ if (loadingDelete) e.preventDefault()})"
      :ok-disabled="loadingDelete"
    >
    <template #modal-title> 
      Delete Environment?
    </template>
    
    <div v-if="deletingEnv">
      Deleting the environment <span class="font-weight-bold text-danger">{{ deletingEnv.name }}</span>
      <div class="small text-secondary">
        This action is irreversible
      </div>
    </div>

    <template #modal-ok>
      <span v-if="!loadingDelete"> Confirm</span>
      <b-spinner v-else small variant="black"/>
    </template>
    </b-modal>

    <request-certificates-modal static ref="request-certificates-modal" />
  
  </div>
</template>

<script>

import Monaco from '@/layouts/components/editor/monaco.vue';
import {
  BButton,
  BOverlay,
  BSkeleton,
  BSpinner,
  BFormInput,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import JsonSkeleton from '@/layouts/components/editor/JsonSkeleton.vue';
import anime from 'animejs';
import JsonEditor from '@/layouts/components/JsonEditor/JsonEditor.vue';
import { mapGetters } from 'vuex';
import VueEventHelper from '@/layouts/components/VueEventHelper.vue';
import { successToast } from '@/custom/class/FunctionClasses/CommonToasts';
import RequestCertificatesModal from '@/views/pages/middleware/MiddlewareManagement/Components/Modals/RequestCertificatesModal.vue';

export default {
  components: {
    BButton,
    BOverlay,
    Monaco,
    JsonSkeleton,
    BSkeleton,
    BSpinner,
    JsonEditor,
    VueEventHelper,
    BFormInput,
    BDropdown,
    BDropdownItem,
    RequestCertificatesModal
  },

  data() {
    return {
      loaded: false,    
      loadingUnlock: false,
      isLocked: true,
      selectedEnv: undefined,
      editingName: false,
      deletingEnv: undefined,
      loadingDelete: false,
      loadingAdd: false,
      canSave: false,
      selectedEnvValue: undefined,
      loadingSave:false,
    };
  },
  mounted() {
    // this.initAnimation()
  },
  computed: {
    ...mapGetters(['getEnvironments']),
    transmissionID(){
      return this.$route.params.transmissionID
    },
  },
  methods: {
    initAnimation(){
      if (this.getEnvironments && this.getEnvironments[0]){
        const stored = localStorage.getItem(`currentEnv-${this.transmissionID}`)
        if (stored && stored.length){
          let env = JSON.parse(stored)
          this.selectEnv(this.getEnvironments.find(el => el.id == env.id))
        } else {
          this.selectEnv(this.getEnvironments[0])
        }
      }
      this.$nextTick(()=>{
        anime({
          targets: '.env-title-block',
          duration: 1500,
          delay: anime.stagger(200),
          opacity: [0, 1],
        })
      })
    },
    addEnvironment(){
      this.loadingAdd = true
      let payload = {
        transmissionID: this.transmissionID
      }
      this.$store.dispatch("addEnvironment",payload)
      .then((resp)=>{
        successToast({
          text: "New environment created."
        })
        this.loadingAdd = false
        this.$nextTick(()=>{
          const target = `.env-title-block.env-title-${resp.id}`
          anime({
            targets: target,
            duration: 500,
            opacity: [0, 1]
          })
          this.selectEnv(resp)
        })
      })
      .catch((err)=>{
        this.loadingAdd = false
        console.error(err)
      })
    },
    saveEnvironment(){
      this.loadingSave = true
      const payload = {
        transmissionID: this.transmissionID,
        value: JSON.stringify(this.selectedEnvValue),
        envId: this.selectedEnv.id,
      }
      this.$store.dispatch('setEnvironment', payload)
        .then((resp)=>{
          successToast({
            text: 'Environment saved.'
          })
          this.canSave = false
          this.loadingSave = false
        })
        .catch((err)=>{
          console.error(err)
          this.loadingSave = false
        })
    },
    saveName(){
      if (this.editingName == this.selectedEnv.name){
        this.editingName = false
        return
      }
      const newName = structuredClone(this.editingName)
      this.selectedEnv.name = newName 
      this.editingName = false

      this.$store.dispatch('editEnvironmentName',{transmissionID: this.transmissionID, envId: this.selectedEnv.id, envName: newName})
      .then((resp)=>{})
      .catch((err)=>{
        console.error(err)
      })
      successToast({text: "Environment name updated"})
    },
    selectEnv(env){
      if (this.selectedEnv && this.selectedEnv.id == env.id){
        return
      }
      localStorage.setItem(`currentEnv-${this.$route.params.transmissionID}`,JSON.stringify(env))      
      this.$store.dispatch('internal/setReactiveEnv')

      this.selectedEnv = structuredClone(env)
      this.isLocked = true
      this.loadingUnlock = false
    },
    unlockEnv(){
      this.loadingUnlock = true

      this.$store.dispatch("getEnvironmentDecrypted",{transmissionID: this.transmissionID, envId: this.selectedEnv.id })
        .then((resp)=>{
          let parsed = resp.value
          try{
            parsed = JSON.parse(parsed)
          }catch(err){
            console.error(err)
          }
          this.selectedEnvValue = parsed
          this.canSave = false

          this.loadingUnlock = false
          this.isLocked = false
          this.$nextTick(() => {
            this.$refs['save-env-btn'].scrollIntoView({alignToTop:false, behavior: "smooth", block: "center"});
          })
        })
        .catch((err)=>{
          console.error(err)
        })
      
    },
    deleteEnvironment(enviroment){
      this.loadingDelete = true
      if(enviroment.id == this.selectedEnv.id){

      }
      this.$store.dispatch('deleteEnvironment',{transmissionID: this.transmissionID, envId: enviroment.id})
        .then((resp)=>{
          this.loadingDelete = false;
          successToast({text: 'Env successfully deleted'})
          this.$refs['modal-delete-env'].hide()
          if (this.selectedEnv.id == enviroment.id){
            this.$nextTick(()=>{
              this.selectEnv(this.getEnvironments[0])
            })
          }
        })
        .catch((err)=>{
          console.error(err)
          this.loadingDelete = false;
        })
    },
    openDeleteModal(environment){
      this.deletingEnv = environment
      this.$refs['modal-delete-env'].show()
    },
    resetInternalFlags(){
      this.loadingUnlock = false,
      this.isLocked = true  
    },
    activeElementBlur(){
      document.activeElement.blur()
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/custom-utils.scss';

$env-black: rgb(73, 97, 116);
$detail-background: mix($secondary, $env-black);
$detail-border-color: #434c53;

.environments-outer-container{
  width: 100%;
  display: flex;
  min-height: 500px;
  
  .list-envs-column{
    padding: 0 10px 0 0;
    width: 33%;
  }
  .details-env-column{
    width: 66%;
    padding: 5px;
    border-radius: 8px;
    box-shadow: inset 0 0 0 1px transparentize($secondary, 0.5);
    background-color: $detail-background;
    border: 4px solid $detail-border-color;
    animation: init-details-column .3s;
    animation-iteration-count: 1;
  }
}

@keyframes init-details-column {
  from{
    opacity: 0.5;
  }
  to{
    opacity: 1; 
  }
}


.env-title-block{
  width: 100%;
  letter-spacing: 1px;
  color: $light;
  padding: 8px 5px;
  margin-bottom: 8px;
  opacity: 0; //this is changed on initAnimation()
  
  background-color: transparentize($env-black, 0.7);
  transition: all 0.2s;

  &:hover, &:active{
    color: $light;
    background-color: lighten(transparentize($env-black, 0.7), 15) ;
  }
  &:active{
    border-color: $light;
    background-color: $env-black;
  }

  &.selected{
    background-color: lighten($env-black, 20);
  }
}

.locked-env-overlay{
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', ;
  height: 100%;
  width: 100%;
  background-color: $detail-background;
  border-radius: 6px;
  color: $black;
  position: relative;
  .centered{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    transition: all 0.2s;
    box-shadow: 0 0 0 30px transparent;
    padding: 10px;
    border: 0px solid transparent;
    &.loading-unlock{
      pointer-events: none;
    }
    &:hover{
      border: 50px solid transparent;
      border-radius: 20px;
      box-shadow: 0 0 0px 2px $detail-border-color;
    }
    .title{
      letter-spacing: 3px;
    }
  }
}
.open-certificates-btn{
  padding: 3px 1px;
  margin: 5px 20px;
  border-radius: 0;
  &:hover{
    border-bottom: 1px solid;
  }
}
</style>

