<template>
<div :style="`max-height: ${maxHeight};`" class="w-100 overflow-hidden ">
  <!-- <div class="w-fit-content"> -->
    <b-skeleton v-for="i in numOfRows" :key="`skeleton-${i}`" :animation="animation" height="16px" :width="randomWidth() + 'px'" :style="`margin-left: ${randomMargin()}px`"/>
  <!-- </div> -->

</div>
</template>

<script>
import {
  BSkeleton,
} from 'bootstrap-vue'
import anime from "animejs";

export default {
  components: {
    BSkeleton,
  },
  data(){
    return{
      numOfRows: 0,
    }
  },
  props: {
    maxHeight:{
      type: String,
      default: "unset"
    },    
    animation:{
      type: String,
      default: undefined
    }
  },
  
  mounted() {
    this.numOfRows = anime.random(20, 50) 
  },
  computed: {
    
  },
  
  methods: {
    randomWidth(){
      return anime.random(100, 800)
    },
    randomMargin(){
      return anime.random(10, 300)
    }
  },
}
</script>
  
  <style lang="scss" scoped >
  
  .editor{
    background-color: #1e1e1e;
  }
  
  
  </style>
  
  
  
  