<template>

</template>

<script>
export default {
  data() {
    return {
      clockTimer: undefined
    }
  },
  props: {
    tickStep: {
      type: Number,
      default: () => undefined         
    },
  },
  mounted(){
    this.$emit('mounted');   

    if (this.tickStep != null){
      this.clockTick()
    }
  },
  methods: {
    stopTick() {
      clearTimeout(this.clockTimer)
    },
    clockTick(){
      this.clockTimer = setTimeout(() => {
        this.$emit('tick')
        this.clockTick()
      }, this.tickStep);
    },
  },
  beforeDestroy() {
    this.stopTick()
    this.$emit('beforeDestroy');
  },
  destroyed(){
    this.$emit('destroyed')
  }
}
</script>