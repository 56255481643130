<template>
<div>

  <b-modal
    ref="certificates-modal"
    centered
    hide-footer
    size="lg"
  > <!-- HTML_ANCHOR LIST CERTIFICATES -->
    <template #modal-title>
      Request Certificates
    </template>
    
    <!-- {{getCertificates}} -->
    
    <div v-if="Array.isArray(getCertificates)" class="certificates-container">
      <div class="d-flex justify-content-between align-items-end">
        <h4 class="mb-75">
          <b-icon icon="file-text"/>
          Certificates
        </h4>

        <b-button variant="none" class="p-0 mb-2" @click="showCreateModal()">
          <span class="text-success">
            <b-icon icon="plus"/>
            Add Certificate
            </span>
        </b-button>
      </div>

      <div v-if="getCertificates.length == 0" align="center" class="my-2">
        <h3 class="text-secondary">No Certificates</h3>
        <div>You can add Certificates to authentificate a Request</div>
      </div>
      
      <div v-for="certificate in getCertificates" class="mb-25 d-flex align-items-center">
        <b-button 
          variant="none" 
          class="certificate-btn" 
          :class="{'selected-certificate':!static && vModel.register_1.value == certificate.identifier}"
          @click="selectCertificate(certificate)"
        >
          <div class="d-flex align-items-center justify-content-between">
            <div>
              {{certificate.name}}
            </div>
            <div class="certificate-date">
              created {{formatDate(certificate.created_at)}}
            </div>
          </div>
        </b-button>
        <b-button variant="outline-danger" size="sm" class="ml-50 px-50 py-50" @click="showDeleteModal(certificate)">
          <b-icon icon="trash"/>
        </b-button>
      </div>
    </div>
    <div v-else class="py-2">
      <div v-for="i in 5" class="d-flex justify-content-between mb-50" :key="'request-certificate-skeleton-'+i">
        <b-skeleton height="35px" width="89%" animation="fade" class="mb-0"/>
        <b-skeleton height="35px" width="9%" animation="fade"  class="mb-0"/>
      </div>
    </div>
  </b-modal>

  <b-modal
    ref="create-certificate-modal"  
    size="md"
    centered
    ok-only 
    :ok-disabled="createBtnDisabled"   
    @hide="(e)=>{if (createData.isLoading) e.preventDefault()}"
    @ok="createCertificate()"
  > <!-- HTML_ANCHOR CREATE CERTIFICATE -->
    <template #modal-title>
      Create Request Certificate
    </template>

    <div class="mb-50">
      <div>
        Name
        <span class="text-warning small" v-if="!createData.name">
          (required)
        </span>
        <span v-else>
          <b-icon icon="check" variant="success"  scale="1.2"/>
        </span>
      </div>
      <b-form-input v-model.trim="createData.name" placeholder="certificate name..."/>
    </div>
          
    <div class="d-flex align-items-center px-2">
      <hr class="py-0 w-100">
      <b-icon icon="circle-fill" class="mx-1" scale="0.2" variant="secondary"/>
      <hr class="py-0 w-100">
    </div>

    <div class="mb-1">
      <div>
        Certificate
        <span class="text-warning small" v-if="!createData.certificate">
          (required)
        </span>
        <span v-else>
          <b-icon icon="check" variant="success"  scale="1.2"/>
        </span>
      </div>
      
      <b-form-file
        @change="(e)=> {inputFile('certificate', e)}"
        placeholder="Choose a file or drop it here..."
        drop-placeholder="Drop file here..."
      />
    </div>
    

    <div class="mb-1">
      <div>
        Private Key
        <span class="text-warning small"  v-if="!createData.privateKey">
          (required)
        </span>
        <span v-else>
          <b-icon icon="check" variant="success" scale="1.2"/>
        </span>
      </div>
      <b-form-file
        @change="(e)=> {inputFile('privateKey', e)}"
        placeholder="Choose a file or drop it here..."
        drop-placeholder="Drop file here..."
      />
    </div>
    

    <div>
      <div>
        Certification Authority (CA)
        <span class="text-secondary small" v-if="!createData.ca">
          (optional)
        </span>
        <span v-else>
          <b-icon icon="check" variant="success" scale="1.2"/>
        </span>
      </div>
        <!-- v-model="createData.ca" -->
      <b-form-file
        @change="(e)=> {inputFile('ca', e)}"
        placeholder="Choose a file or drop it here..."
        drop-placeholder="Drop file here..."
      />
    </div>


    <template #modal-ok>
      <span class="text-black" v-if="!createData.isLoading">
        Create Certificate
      </span>
      <b-spinner v-else small variant="black" class="mx-3"/>
    </template>
  </b-modal>
  
  <b-modal
    ref="delete-certificate-modal"
    centered
    ok-only
    ok-variant="danger"
    :ok-disabled="loadingDelete"
    @ok="confirmDelete()"
    @hide="(e) => { if(loadingDelete){ e.preventDefault() } }"
  > <!-- HTML_ANCHOR - DELETE CERTIFICATE -->
    <template #modal-title>
    Deleting Certificate
    </template>

    <div v-if="currentDelete">
      Delete the certificate <b>{{currentDelete.name}}</b>?
    </div>
    <template #modal-ok>
      <span v-if="!loadingDelete">
        Delete
      </span>
      <b-spinner small v-else/>
    </template>
  </b-modal>

</div>
</template>

<script>
import {
  BContainer,
  BSidebar,
  BRow,
  BCol,
  BButton,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BFormGroup,
  BForm,
  BTab,
  BFormSelect,
  BTabs,
  BFormSelectOption,
  BFormCheckbox,
  BSpinner,
  BSkeleton,
  BFormFile,
} from "bootstrap-vue";
import {mapGetters} from 'vuex';
import moment from 'moment';
import { errorToast, successToast } from '@/custom/class/FunctionClasses/CommonToasts';

export default {
  components: {
    BContainer,
    BSidebar,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormGroup,
    BForm,
    BTab,
    BFormSelect,
    BTabs,
    BFormSelectOption,
    BFormCheckbox,
    BSpinner,
    BSkeleton,
    BFormFile,
  }, 
  data() {
    return {
      createData: {
        isLoading: false,
        nameState: null,

        name: undefined,
        ca: undefined,
        certificate: undefined,
        privateKey: undefined,
      },
      currentDelete: undefined,
      loadingDelete: false,
    }
  },
  props: {
    isInput: {
      type: Boolean,
      default: false, 
    },
		value: {
			type: Object,
			default: ()=>{}
		},
    static: {
      type: Boolean,
      default: false,
    }
  },
	computed: {
    ...mapGetters('certificate', ['getCertificates']),
		vModel: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			}
		},
    orgId(){
      return localStorage.getItem('selectedOrganization')
    },
    createBtnDisabled(){
      const createData = this.createData
      const missingRequiredField = (!createData.name || !(createData.privateKey && createData.certificate))
      return (createData.isLoading || missingRequiredField)
    }
	},
  methods: {
    show() {
      this.$refs['certificates-modal'].show()  
      this.$store.dispatch('certificate/fetchCertificates', {orgId: this.orgId})  
        .then()
    },
    showCreateModal(){
      this.createData = {
        isLoading: false,
        nameState: null,

        name: undefined,
        ca: undefined,
        certificate: undefined,
        privateKey: undefined,
      }
      this.$refs['create-certificate-modal'].show()
    },
    showDeleteModal(certificate){
      this.currentDelete = certificate
      this.$refs['delete-certificate-modal'].show()
    },
    formatDate(d){
      return new moment(d).format('L')
    },
    createCertificate(){
      this.createData.isLoading = true;
      const payload = {
        data: {
          "org_id": this.orgId,
          "name": this.createData.name,

          "ca_content": this.createData.ca || undefined,
          "certificate_content": this.createData.certificate  || undefined,
          "priv_key_content": this.createData.privateKey  || undefined
        },
        orgId: this.orgId
      }
      this.$store.dispatch('certificate/createCertificate', payload )
        .then((resp=>{
          this.createData.isLoading = false;
          this.$nextTick(()=>{
            this.$refs['create-certificate-modal'].hide()
          })
        }))
        .catch((err)=>{
          console.error(err)
          errorToast({text: "Couldn't create Certificate"})
          this.createData.isLoading = false;
        })
     },
    confirmDelete(){
      this.loadingDelete = true
      const cert = this.currentDelete
      this.$store.dispatch('certificate/deleteCertificate', {certificateId: cert.id, orgId: this.orgId} )
        .then((resp)=>{
          this.loadingDelete = false
          this.$refs['delete-certificate-modal'].hide()
          successToast({text: 'Certificate deleted'})
        })
        .catch((err)=>{
          this.loadingDelete = false
          console.error(err)
        })
    },
    inputFile(field, event){
      let file = event.target.files[0]
      

      this.createData[field] = event.target.files
      var fr = new FileReader();
      fr.onload = e => {
        this.createData[field] = e.target.result;
      }
      fr.readAsText(file);
    },
    selectCertificate(certificate){
      if (this.static){
        return
      }
      this.vModel.register_1.value = certificate.identifier
      this.vModel.register_2 = {
        value: certificate.name,
        source: '7'
      }
      this.$refs['certificates-modal'].hide()
    }
  }, 
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/custom-utils.scss';

.certificates-container{
  min-height: 150px;
}
.certificate-btn{
  width: 100%;
  text-align: left;
  padding: 5px 10px;
  font-weight: bolder;
  font-size: 15px;
  color: transparentize($light, 0.2);
  background-color: transparentize($purple, 0.8);
  outline: 1px solid transparent;
  transition: all 0.2s;
  //border: 1px solid $success;
  //color: $black;
  //background-color: $favorite;
  &:hover, &:focus{
    background-color: transparentize($purple, 0.5);
  }
  &:active{
    outline: 1px solid $secondary;
  }
  .certificate-date{
    font-size: 12px;
    font-weight: normal;
    color: transparentize($light, 0.3);
  }
  &.selected-certificate{
    background-color: $purple;
    outline: 1px solid $secondary;
  }
}
</style>